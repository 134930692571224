/**
 * Copyright (C) Siemens AG 2022. All Rights Reserved. Confidential

 * About File description :
 *
 * This file CustomeFormValidationService service Validate input data.
 *
 *
*/

import { Injectable,Inject } from '@angular/core';
import { ValidatorFn, AbstractControl, ValidationErrors, FormControl, Validators } from '@angular/forms';
import { AuthService } from '@auth0/auth0-angular';
import {
  AuthConfigService,
  AuthorizationParams,
  AuthService as Auth0Service,
  AuthConfig as Auth0Config,
  LogoutOptions,
} from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from '@ng-web-apis/common';
@Injectable({
  providedIn: 'root'
})
export class CustomeFormValidationService {
  public get doc(): Document {
    return this._doc;
  }
  public set doc(value: Document) {
    this._doc = value;
  }

  constructor( public auth: AuthService,
    @Inject(DOCUMENT)
    private _doc: Document,
    @Inject(WINDOW) private window: Window,
    @Inject(AuthConfigService) private auth0Config: Auth0Config,) {
    }

  patternValidator(): ValidatorFn {
    return (control: AbstractControl):  ValidationErrors | null=> {
      if (!control.value) {
        return null;
      }
      const IsEmailText=control.value.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    if(IsEmailText)
    {
      return { urlData: true };
    }
      const urlPattern = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+\/?|localhost|(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}))(\/\S*)?$/;
      const valid = urlPattern.test(control.value);
      return valid ? { urlData: true }:null;
    };
  }

   encodeText(input:string) {
    var textArea = document.createElement('textarea');
    textArea.innerText = input;
    return textArea.innerHTML;
  }
  
  
  
  logout() {
    this.auth.logout({ logoutParams: { returnTo: this.doc.location.origin+'/logout' } ,openUrl: async (url) => {
      this.window.open(this.getLogoutUri(url), '_self');
    },});
  }

  private getLogoutUri(logoutUrl: string) {
    const federatedQuery = '&federated';

    const auth0MainTenantDomain = this.auth0Config.domain.startsWith(
      'siemens-qa-',
    )
      ? 'login-qa.siemens.com'
      : 'login.siemens.com';

    const url = `https://${auth0MainTenantDomain}/v2/logout?returnTo=${encodeURIComponent(
      logoutUrl,
    )}`;

    return url + federatedQuery;
  }
}
