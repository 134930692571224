/**
 * Copyright (C) Siemens AG 2022. All Rights Reserved. Confidential
 * About File description :
 * This file AppComponent is used to intialize the application.
 */

import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  Injector,
  OnInit,
  SecurityContext,
} from '@angular/core';
import { siRouterFadeAnimation } from '@simpl/siemens-brand-ng/tools';
import { BackendDataService } from './services/backend-data.service';
import { TranslateService } from '@ngx-translate/core';
import { UnitHandlingService } from './services/unit-handling.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CancelModalComponent } from './Modals/cancel-modal/cancel-modal.component';
import { Subscription } from 'rxjs';
import { DisclaimerModalComponent } from './Modals/disclaimer-modal/disclaimer-modal.component';
import { DomSanitizer } from '@angular/platform-browser';
import { DataStoreService } from './services/data-store.service';
import { UtilsService } from './services/utils.service';
import { DOCUMENT } from '@angular/common';
import { UntypedFormControl } from '@angular/forms';
import { NgxLoaderService } from './services/ngx-loader.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomeFormValidationService } from './services/custome-form-validation.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [siRouterFadeAnimation],
})
export class AppComponent implements OnInit {
  public links = ['configuration'];
  public i: number;
  j: number;
  applicationName!: string;
  applicationUrl!: string;
  version: string;
  copyright!: string;
  copyrightYear!: string;
  public load = true;
  footerLinks = [];
  contentContainer = 'container';
  company = 'sag';
  public modalRef: BsModalRef;
  public modalRefDiscalaimer: BsModalRef;
  public userLanguageList = [
    { value: 'en', name: 'TID_English', selected: true },
    { value: 'de', name: 'TID_German', selected: false },
  ];
  today = new Date();
  public selectedLanguage: { value: string };
  public displayHeaderMenu: boolean = false;
  subscription: Subscription = new Subscription();
  subscribe: Subscription = new Subscription();
  routeToHome: boolean = false;
  display: boolean;
  displayEmail: boolean;
  skipLinkPath: string;
  public label: string = 'The language change button';
  contactSupportUrl: string;
  displayTheme: boolean;
  formControl!: UntypedFormControl;
  headerClass: string = 'si-header si-header-extended fixed-top bg-light';
  light: string;
  dark: string;

  constructor(
    private readonly modalService: BsModalService,
    public service: BackendDataService,
    public translate: TranslateService,
    public unitHandling: UnitHandlingService,
    public dataStoreService: DataStoreService,
    private readonly router: Router,
    public readonly sanitizer: DomSanitizer,
    public utilsService: UtilsService,
    public route: ActivatedRoute,
    private readonly injector: Injector,
    private loader: NgxLoaderService,
    private cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private readonly document: Document,
    public customeFormValidationService:CustomeFormValidationService
  ) {
    this.loader.presentLoading();
    this.version=this.utilsService.version;
    let userLang: string;
    const langBeforePageRefresh: string = localStorage.getItem('user_lang');
    if (langBeforePageRefresh) {
      userLang = langBeforePageRefresh;
      sessionStorage.setItem('user_lang', userLang);
    } else {
      const bowswerLang = this.translate.getBrowserLang();
      if (bowswerLang === 'en' || bowswerLang === 'de') {
        sessionStorage.setItem('user_lang', bowswerLang);
      } else {
        sessionStorage.setItem('user_lang', 'de');
      }
     
      userLang = sessionStorage.getItem('user_lang');
    }
    this.document.documentElement.lang = userLang;
    if (userLang === 'en') {
      this.i = 0;
      this.j = 1;
    } else {
      this.i = 1;
      this.j = 0;
    }
    this.translate.setDefaultLang(userLang ? userLang : 'de');
    this.translate.currentLang = userLang;
    this.showContactInfo(userLang);
    this.setFooterLinks();
    this.formControl = new UntypedFormControl(null);
    const theme = JSON.parse(sessionStorage.getItem('Theme'));
    if (theme) {
      this.loadStyle(theme);
    } else {
      setTimeout(() => {
        const setValue = this.translate.instant('TID_Light');
        this.formControl.setValue(setValue, {
          emitEvent: false,
        });
        sessionStorage.setItem('Theme',JSON.stringify('siemens.css'));
      }, 5000);
    }
    this.service.getUserName();
    this.themeChange();
    this.loader.dismissLoading();
  }

  showThemeSettings(event: boolean) {
    if (event === true) {
      this.displayTheme = true;
    } else {
      this.displayTheme = false;
    }
    this.display = false;
    this.displayEmail = false;
    this.updateThemeValue();
  }

  closePopUp(event: boolean, route: string) {
    if (event === true) {
      this.displayTheme = false;
      this.displayEmail = false;
      this.display = false;
      if (
        this.router.routerState.snapshot.url === '/main/configuration' ||
        this.router.routerState.snapshot.url === '/main/process-parameters' ||
        this.router.routerState.snapshot.url === '/main/results'
      ) {
        this.utilsService.setupPageData?.isDataChangedByUI();
        this.utilsService.processParametersPage?.isDataChangedByUI();
        if(this.utilsService.isLoadedDateChanged)
        {
          const modalService: BsModalService = this.injector.get(BsModalService);
          const modal = modalService.show(CancelModalComponent, {
            backdrop: 'static',
          }).content;
          this.subscription = modalService.onHide.subscribe(() => {
            if (modal) {
              if (modal.routeCheck === true) {
                this.router.navigateByUrl(route);
                modal.routeCheck = false;
              }
            }
          });
        }
        else
        {
          this.router.navigateByUrl(route);
        }
        
      } else {
        this.router.navigateByUrl(route);
      }
    }
  }

  themeChange() {
    this.formControl.valueChanges.subscribe((value) => {
      let styleValue: string = null;
      this.utilsService.clearAllFilterData(true);
      if (value === this.translate.instant('TID_Dark')) {
        styleValue = 'dark.css';
      } else {
        styleValue = 'siemens.css';
      }
      this.loadStyle(styleValue);
    });
  }

  loadStyle(style: string) {
    this.loader.presentLoading();
    sessionStorage.setItem('Theme', JSON.stringify(style));
    const head = this.document.getElementsByTagName('head')[0];
    const themeLink = this.document.getElementById('theme') as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = style;
      this.displayTheme = false;
    } else {
      const styleElement = this.document.createElement('link');
      styleElement.type = 'text/css';
      styleElement.id = 'theme';
      styleElement.rel = 'stylesheet';
      styleElement.href = `${style}`;
      head.appendChild(styleElement);
      this.displayTheme = false;
    }
    this.setHeaderTheme(style);
    this.loader.dismissLoading();
  }

  setHeaderTheme(styleFile: string) {
    setTimeout(() => {
      if (styleFile === 'dark.css') {
        this.headerClass = 'si-header si-header-extended fixed-top bg-dark';
        const formValueDark = this.translate.instant('TID_Dark');
        this.formControl.setValue(formValueDark, {
          emitEvent: false,
        });
      } else {
        this.headerClass = 'si-header si-header-extended fixed-top bg-light';
        const formValueLight = this.translate.instant('TID_Light');
        this.formControl.setValue(formValueLight, {
          emitEvent: false,
        });
      }
    }, 200);
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    const lang = sessionStorage.getItem('user_lang');
    if (lang) {
      localStorage.setItem('user_lang', lang);
    }
  }

  /**
   * To check whether the language change pop-up needs to be displayed or not
   * @param event : Boolean value to check the popup
   */
  showData(event: boolean) {
    const loadId = localStorage.getItem('loadId');
    if (!loadId) {
      if (event === true) {
        this.display = true;
      } else {
        this.display = false;
      }
    } else {
      this.display = false;
    }
    this.displayEmail = false;
    this.displayTheme = false;
  }

  /**
   * To open the help document in a new browser tab
   */
  showHelpDocument() {
    window.open('assets/remote_seal_help/start.html', '_blank').focus();
  }

  /**
   * To open the PIA in a new browser tab
   */
  openPIALink() {
    const piaURL = this.sanitizer.sanitize(SecurityContext.URL, 'https://pia-portal.automation.siemens.com/default.htm');
    window.open(piaURL, '_blank').focus();
  }

  /**
   * To open the Catalog in a new browser tab
   */
  openCatalogLink() {
    let catalogURL='';
    const selectedLanguage=sessionStorage.getItem('user_lang');
    if (selectedLanguage === 'en') {
      catalogURL =
      this.sanitizer.sanitize(SecurityContext.URL,'https://support.industry.siemens.com/cs/products?dtp=Catalog&mfn=ps&pnid=17253&lc=en-WW');

    } else {
      catalogURL =
      this.sanitizer.sanitize(SecurityContext.URL,'https://support.industry.siemens.com/cs/products?dtp=Catalog&mfn=ps&pnid=17253&lc=de-WW');
    
    }
    window.open(catalogURL, '_blank').focus();
  }

  /**
   *To check whether the email pop-up needs to be displayed or not
   * @param event  Boolean value to check the popup
   */
  showEmailData(event: boolean) {
    if (event === true) {
      this.displayEmail = true;
    } else {
      this.displayEmail = false;
    }
    this.displayTheme = false;
    this.display = false;
  }

  showContactInfo(selectedLang: string) {
    if (selectedLang === 'en') {
      this.contactSupportUrl =
        'https://support.industry.siemens.com/cs/start?lc=en-WW';
      this.sanitize(this.contactSupportUrl);
    } else {
      this.contactSupportUrl =
        'https://support.industry.siemens.com/cs/start?lc=de-WW';
      this.sanitize(this.contactSupportUrl);
    }
  }

  /**
   * Changes the application language based on the router url
   * @param selectLanguage : The language selected by the user
   */
  public selectLanguage(selectedLanguage: {
    value: string;
    name?: string;
    selected?: boolean;
  }) {
    if (
      this.router.routerState.snapshot.url === '/main/configuration' ||
      this.router.routerState.snapshot.url === '/main/process-parameters' ||
      this.router.routerState.snapshot.url === '/main/results'
    ) {
      this.modalRef = this.modalService.show(CancelModalComponent, {
        backdrop: 'static',
      });
      this.routeToHome = true;
      this.subscribeToModal(selectedLanguage);
    } else {
      this.changeLanguege(selectedLanguage);
      this.routeToHome = false;
    }
  }

  /**
   * sets all the languages in the given list to false
   */

  public resetLanguageSelection() {
    this.userLanguageList.forEach((userLanguage) => {
      userLanguage.selected = false;
    });
  }

  /**
   * Subscribe to the cancel-modal to check if the language needs to be changed
   * @param selectLanguage : The language selected by the user
   */
  subscribeToModal(selectLanguage: {
    value: string;
    name?: string;
    selected?: boolean;
  }) {
    this.subscription = this.modalService.onHide.subscribe(() => {
      if (this.modalRef) {
        if (
          this.modalRef.content.languageChange === true &&
          this.routeToHome === true
        ) {
          this.changeLanguege(selectLanguage);
          this.router.navigateByUrl('/');
        } else {
          this.display = false;
        }
      }
    });
  }

  /**
   * Change the application lanague according to the configuration being loaded
   */

  languageChangeOnLoad() {
    this.subscribe = this.dataStoreService
      .getData(this.dataStoreService.languageChangeOnLoad)
      .subscribe({
        next: (val) => {
          let TID = null;
          if (val === 'en') {
            TID = 'TID_English';
          } else {
            TID = 'TID_Deutsch';
          }
          const selectedLanguage = {
            value: val,
            name: TID,
            selected: true,
          };
          this.changeLanguege(selectedLanguage);
          //  this.utilsService.onlanguageToggle = 'manually';
        },
      });
  }

  /**
   * Change the application languge as per the user selection
   * @param selectLanguage : The language selected by the user
   */
  changeLanguege(selectLanguage: {
    value: string;
    name?: string;
    selected?: boolean;
  }) {
    this.display = false;
    this.resetLanguageSelection();
    if (selectLanguage.value === 'en') {
      this.i = 0;
      this.j = 1;
    } else {
      this.i = 1;
      this.j = 0;
    }
    this.selectedLanguage = selectLanguage;
    this.document.documentElement.lang = selectLanguage.value;
    this.translate.use(this.selectedLanguage.value);
    this.showContactInfo(this.selectedLanguage.value);
    this.translate.currentLang = this.selectedLanguage.value;
    sessionStorage.setItem('user_lang', this.selectedLanguage.value);
    this.routeToHome = false;
    this.setFooterLinks();
    const headerTheme = JSON.parse(sessionStorage.getItem('Theme'));
    this.setHeaderTheme(headerTheme);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  /**
   * To display the footer links based on the application language
   */

  setFooterLinks() {
    const language = sessionStorage.getItem('user_lang');
    if (language === 'en') {
      this.footerLinks = [
        {
          url: this.sanitize(
            'https://new.siemens.com/global/en/general/legal.html'
          ),
          name: 'TID_CorporateInformation',
        },
        {
          url: this.sanitize(
            'https://new.siemens.com/global/en/general/privacy-notice.html'
          ),
          name: 'TID_PrivacyNote',
        },
        {
          url: this.sanitize(
            'https://new.siemens.com/global/en/general/cookie-notice.html'
          ),
          name: 'TID_CookieNote',
        },
        {
          url: this.sanitize(
            'https://new.siemens.com/global/en/general/terms-of-use.html'
          ),
          name: 'TID_TermsOfUse',
        },
        {
          url: this.sanitize(
            'https://new.siemens.com/global/en/general/digital-id.html'
          ),
          name: 'TID_DigitalId',
        },
      ];
    } else {
      this.footerLinks = [
        {
          url: this.sanitize(
            'https://new.siemens.com/global/de/general/legal.html'
          ),
          name: 'TID_CorporateInformation',
        },
        {
          url: this.sanitize(
            'https://new.siemens.com/global/de/general/datenschutz.html'
          ),
          name: 'TID_PrivacyNote',
        },
        {
          url: this.sanitize(
            'https://new.siemens.com/global/de/general/cookie-richtlinien.html'
          ),
          name: 'TID_CookieNote',
        },
        {
          url: this.sanitize(
            'https://new.siemens.com/global/de/general/nutzungsbedingungen.html'
          ),
          name: 'TID_TermsOfUse',
        },
        {
          url: this.sanitize(
            'https://new.siemens.com/global/de/general/digitales-zertifikat.html'
          ),
          name: 'TID_DigitalId',
        },
      ];
    }
  }

  ngOnInit() {
    if (this.dataStoreService.languageChangeOnLoad) {
      this.dataStoreService.createData(
        this.dataStoreService.languageChangeOnLoad
      );
      this.languageChangeOnLoad();
    }
  }

  /**
   * Displays the Disclaimer modal
   */
  public showDiscalaimer($event) {
    $event.preventDefault();
    this.modalRefDiscalaimer = this.modalService.show(
      DisclaimerModalComponent,
      { backdrop: 'static' }
    );
    this.modalRefDiscalaimer.setClass('modal-lg');
  }


  showOpenSourceLicenses($event)
  {
    $event.preventDefault();
    window.open('assets/ossFile/RemoteSealCalculationTool.html', '_blank').focus();
  }

  // Navigates to the Home component from the app component
  getRouteAnimation(outlet) {
    return outlet.activated ? outlet.activatedRoute : null;
  }

  skipToMain(id) {
    this.router.navigate([], { fragment: id });
    let element;
    if (id === 'footer-content') {
      element = document.querySelector('[role="contentinfo"]') as HTMLElement;
    } else {
      element = document.querySelector('[id="main-content"]') as HTMLElement;
    }
    element.scrollTo();
    element.setAttribute('tabindex', '-1');
    element.focus();
  }

  updateThemeValue() {
    const headerTheme = JSON.parse(sessionStorage.getItem('Theme'));
    if (headerTheme === 'dark.css') {
        const formValueDark = this.translate.instant('TID_Dark');
        this.formControl.setValue(formValueDark, {
            emitEvent: false,
        });
    } else {
        const formValueLight = this.translate.instant('TID_Light');
        this.formControl.setValue(formValueLight, {
            emitEvent: false,
        });
    }
}

logout()
{
this.customeFormValidationService.logout();
}
}
