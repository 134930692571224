/**
 * Copyright (C) Siemens AG 2022. All Rights Reserved. Confidential

 * About File description :
 *
 * This file ResultCalculationService service provides the
 * to save the data to backend.
 *
 *
*/

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    CalculationResult,
    GlobalConstants,
    ILabomResult,
    IResponseTime,
} from '../common/global-constants';
import { DataStoreService } from './data-store.service';
import { UtilsService } from './utils.service';
import { UnitHandlingService } from './unit-handling.service';
import { LabomResult, OptionType } from './load-data';

@Injectable({
    providedIn: 'root',
})
export class ResultCalculationService {
    overallSystem: number;
    trafficLightText: string;
    numberOfDecimalPlaces = 100;
    threeDecimalPlaces = 1000;
    twentyEight = 28;
    two = 2;
    unit: string;
    capillaryLengthMLFBHighSide: OptionType;
    capillaryLengthMLFBLowSide: OptionType;
    extensionLengthMLFBHighSide: OptionType;
    extensionLengthMLFBLowSide: OptionType;
    functionalityLabel: string;
    calibrationLabel: string;
    temperatureErrorLabel: string;
    instrumentType: string = localStorage.getItem('InstrumentType');
    showOptimalCalculation = false;
    temperatureUnit = '\xB0C';
    calculationOptimalResult: CalculationResult;
    calculationResult: CalculationResult;
    calculationResultFromAPI: any[];
    public calculation = null;
    public instrumentCode = null;
    public maxmeasuringspan = null;
    constructor(
        private readonly dataStoreService: DataStoreService,
        private readonly translate: TranslateService,
        private readonly unitHandlingService: UnitHandlingService,
        public utilsService: UtilsService
    ) {
        this.calculationResult = new CalculationResult();
        this.calculationOptimalResult = new CalculationResult();
    }

    /**
     * Set pressure transmitter data for calculation request
     */
    setInstrumentData() {
        const instrumentID = localStorage.getItem('TransmitterId');
        const InstrumentVersionID = localStorage.getItem('InstrumentVersionId');
        this.utilsService.dataForCalculation.calculationRequest.instrument.instrumentID =
            Number(instrumentID);
        this.utilsService.dataForCalculation.calculationRequest.instrument.instrumentVersionID =
            Number(InstrumentVersionID);
    }

    /**
     * Set Remote seal high side data for calculation request
     */
    setDiaphragmSealData() {
        const diaphragmIdHigh = localStorage.getItem('diaphragmId');
        const versionIdHigh = localStorage.getItem('versionId');
        const DiaphragmFunctionUnitIdHigh = localStorage.getItem(
            'DiaphragmFunctionUnitId'
        );
        this.utilsService.dataForCalculation.calculationRequest.plusSide.diaphragmSealID =
            Number(diaphragmIdHigh);
        this.utilsService.dataForCalculation.calculationRequest.plusSide.diaphragmSealVersionID =
            Number(versionIdHigh);
        this.utilsService.dataForCalculation.calculationRequest.plusSide.diaphragmSealFunctionUnitID =
            Number(DiaphragmFunctionUnitIdHigh);
    }

    /**
     * Set Remote seal low side data for calculation request
     * @param diaphragmIdLow : Remote seal type low side id
     * @param versionIdLow: Remote seal standard low side id
     * @param DiaphragmFunctionUnitIdLow: Diaphragm material low side id
     */
    setDiaphragmSealDataMinusSide(
        diaphragmIdLow: string,
        versionIdLow: string,
        DiaphragmFunctionUnitIdLow: string
    ) {
        if (diaphragmIdLow && versionIdLow && DiaphragmFunctionUnitIdLow) {
            this.utilsService.dataForCalculation.calculationRequest.minusSide =
                {
                    diaphragmSealID: 0,
                    diaphragmSealVersionID: 0,
                    diaphragmSealFunctionUnitID: 0,
                    extensionLength: 0,
                    fluidID: 0,
                    capillary: {
                        length: 0,
                        diameter: 0,
                    },
                    mountingElement1ID: 0,
                    mountingElement2ID: 0,
                    heightInstrumentOverDiaphragmSeal: 0,
                    tAmbientMin: 0,
                    tAmbientMax: 0,
                    tProcessMin: 0,
                    tProcessMax: 0,
                    negativePressureService: null,
                };
            this.utilsService.dataForCalculation.calculationRequest.minusSide.diaphragmSealID =
                Number(diaphragmIdLow);
            this.utilsService.dataForCalculation.calculationRequest.minusSide.diaphragmSealVersionID =
                Number(versionIdLow);
            this.utilsService.dataForCalculation.calculationRequest.minusSide.diaphragmSealFunctionUnitID =
                Number(DiaphragmFunctionUnitIdLow);
        }
    }

    /**
     * Set mounting high side data for calculation request
     */
    setMountingData() {
        const fillingFluidId = localStorage.getItem('FillingFluid');
        this.utilsService.dataForCalculation.calculationRequest.plusSide.fluidID =
            Number(fillingFluidId);
        const mountingElemntID = localStorage.getItem('MountingID');
        this.utilsService.dataForCalculation.calculationRequest.plusSide.mountingElement1ID =
            Number(mountingElemntID);
    }

    /**
     * Set mounting low side data for calculation request
     * @param mountingElemntID : Moutning type low side id
     * @param length: capillary length for low side
     * @param height: height difference of low side
     * @param diameter: inner diameter for low side
     */
    setMountingDataMinusSide(
        mountingElemntID: string,
        length: number,
        height: number,
        diameter: number
    ) {
        if (
            this.utilsService.dataForCalculation.calculationRequest
                .minusSide !== null
        ) {
            const fillingFluidId = localStorage.getItem('FillingFluid');
            this.utilsService.dataForCalculation.calculationRequest.minusSide.fluidID =
                Number(fillingFluidId);
            this.utilsService.dataForCalculation.calculationRequest.minusSide.mountingElement1ID =
                Number(mountingElemntID);
            if (length) {
                this.utilsService.dataForCalculation.calculationRequest.minusSide.capillary.length =
                    length;
                this.utilsService.configurationData.minusSide.capillary.length =
                    length;
            }
            if (diameter) {
                this.utilsService.dataForCalculation.calculationRequest.minusSide.capillary.diameter =
                    Number(diameter);
                this.utilsService.configurationData.minusSide.capillary.diameter =
                    Number(diameter);
            } else if (length) {
                this.utilsService.dataForCalculation.calculationRequest.minusSide.capillary.diameter = 1.3;
                this.utilsService.configurationData.minusSide.capillary.diameter = 1.3;
            } else {
                this.utilsService.dataForCalculation.calculationRequest.minusSide.capillary.diameter = 0;
                this.utilsService.configurationData.minusSide.capillary.diameter = 0;
            }
            if (height === 0 || height === null || height === undefined) {
                this.utilsService.dataForCalculation.calculationRequest.minusSide.heightInstrumentOverDiaphragmSeal = 0;
                this.utilsService.configurationData.minusSide.heightInstrumentOverDiaphragmSeal = 0;
            } else {
                this.utilsService.dataForCalculation.calculationRequest.minusSide.heightInstrumentOverDiaphragmSeal =
                    height;
                this.utilsService.configurationData.minusSide.heightInstrumentOverDiaphragmSeal =
                    height;
            }
        }
    }

    /**
     * Set Mounting data high side for capillary to calculation request
     * @param length: capillary length for high side
     * @param height: height difference of high side
     * @param diameter: inner diameter for high side
     */
    setMountingCapillary(length: number, height: number, diameter: number) {
        this.utilsService.dataForCalculation.calculationRequest.plusSide.capillary.length =
            Number(length);
        this.utilsService.configurationData.plusSide.capillary.length =
            Number(length);
        if (diameter) {
            this.utilsService.dataForCalculation.calculationRequest.plusSide.capillary.diameter =
                Number(diameter);
            this.utilsService.configurationData.plusSide.capillary.diameter =
                Number(diameter);
        } else if (length) {
            this.utilsService.dataForCalculation.calculationRequest.plusSide.capillary.diameter = 1.3;
            this.utilsService.configurationData.plusSide.capillary.diameter = 1.3;
        } else {
            this.utilsService.dataForCalculation.calculationRequest.plusSide.capillary.diameter = 0;
            this.utilsService.configurationData.plusSide.capillary.diameter = 0;
        }
        if (height === 0 || height === null) {
            this.utilsService.dataForCalculation.calculationRequest.plusSide.heightInstrumentOverDiaphragmSeal = 0;
            this.utilsService.configurationData.plusSide.heightInstrumentOverDiaphragmSeal = 0;
        } else {
            this.utilsService.dataForCalculation.calculationRequest.plusSide.heightInstrumentOverDiaphragmSeal =
                height;
            this.utilsService.configurationData.plusSide.heightInstrumentOverDiaphragmSeal =
                height;
        }
    }

    /**
     * set process-parameter values to calculaation request
     * @param pMin : Minimum measuring range
     * @param pMax : Maximum measuring range
     * @param pstatic: static pressure
     * @param min : Minimum ambient temperature
     * @param normal: Nominal amibient tempearture
     * @param max: Maximum ambient temperature
     * @param processMin : Minimum process temperature
     * @param processNormal: Nominal process tempearture
     * @param processMax: Maximum process temperature
     * @param vaccumService: Negative pressure service
     */
    setProcessParametersData(
        pMin: number,
        pMax: number,
        pstatic: number,
        min: number,
        normal: number,
        max: number,
        processMin: number,
        processNormal: number,
        processMax: number,
        vaccumService: { vaccum_service: string }
    ) {
        this.utilsService.dataForCalculation.calculationRequest.pMin = pMin;
        this.utilsService.dataForCalculation.calculationRequest.pMax = pMax;
        this.utilsService.dataForCalculation.calculationRequest.tAmbient1 =
            Number(min);
        this.utilsService.dataForCalculation.calculationRequest.tAmbient2 =
            Number(normal);
        this.utilsService.dataForCalculation.calculationRequest.tAmbient3 =
            Number(max);
        this.utilsService.dataForCalculation.calculationRequest.plusSide.tAmbientMin =
            Number(min);
        this.utilsService.dataForCalculation.calculationRequest.plusSide.tAmbientMax =
            Number(max);
        this.utilsService.dataForCalculation.calculationRequest.plusSide.tProcessMin =
            Number(processMin);
        this.utilsService.dataForCalculation.calculationRequest.plusSide.tProcessMax =
            Number(processMax);
        this.setStaticPressure(pstatic);
        if (vaccumService.vaccum_service === '0') {
            this.utilsService.dataForCalculation.calculationRequest.plusSide.negativePressureService =
                'None';
        } else if (vaccumService.vaccum_service === '1') {
            this.utilsService.dataForCalculation.calculationRequest.plusSide.negativePressureService =
                'Standard';
        } else {
            this.utilsService.dataForCalculation.calculationRequest.plusSide.negativePressureService =
                'Extended';
        }
        if (
            this.utilsService.dataForCalculation.calculationRequest
                .minusSide !== null
        ) {
            this.utilsService.dataForCalculation.calculationRequest.minusSide.tAmbientMin =
                Number(min);

            this.utilsService.dataForCalculation.calculationRequest.minusSide.tAmbientMax =
                Number(max);

            this.utilsService.dataForCalculation.calculationRequest.minusSide.tProcessMin =
                Number(processMin);

            this.utilsService.dataForCalculation.calculationRequest.minusSide.tProcessMax =
                Number(processMax);

            this.utilsService.dataForCalculation.calculationRequest.minusSide.negativePressureService =
                this.utilsService.dataForCalculation.calculationRequest.plusSide.negativePressureService;
        }
    }

    /**
     * set static pressure for calculation request
     * @param pStatic : static pressure value
     */
    setStaticPressure(pStatic: number) {
        if (pStatic == null || Number.isNaN(pStatic)) {
            this.utilsService.dataForCalculation.calculationRequest.pMaxStatic =
                null;
        } else {
            this.utilsService.dataForCalculation.calculationRequest.pMaxStatic =
                Number(pStatic);
        }
    }

    /**
     * Sets process parameter values for result calculation
     * @param ambientMin :  Minimum ambient temperature
     * @param ambientNormal :Nominal amibient tempearture
     * @param ambientMax:Maximum ambient temperature
     * @param processMin: Minimum process temperature
     * @param processNormal: Nominal process tempearture
     * @param processMax: Maximum process temperature
     * @param pressureMin: Minimum measuring range
     * @param pressureMax: Maximum measuring range
     * @param staticPressure: static pressure
     * @returns calculaation data object
     */
    setCalculationData(
        ambientMin: number,
        ambientNormal: number,
        ambientMax: number,
        processMin: number,
        processNormal: number,
        processMax: number,
        pressureMin: number,
        pressureMax: number,
        staticPressure: number
    ) {
        this.utilsService.dataForCalculation.calculationData.tAmbientMin =
            Number(ambientMin);
        this.utilsService.dataForCalculation.calculationData.tAmbientMax =
            Number(ambientMax);
        this.utilsService.dataForCalculation.calculationData.tAmbientNormal =
            Number(ambientNormal);
        this.utilsService.dataForCalculation.calculationData.tProcessMin =
            Number(processMin);
        this.utilsService.dataForCalculation.calculationData.tProcessMax =
            Number(processMax);
        this.utilsService.dataForCalculation.calculationData.tProcessNormal =
            Number(processNormal);
        this.utilsService.dataForCalculation.calculationData.measuringSpanMin =
            Number(pressureMin);
        this.utilsService.dataForCalculation.calculationData.measuringSpanMax =
            Number(pressureMax);
        this.instrumentType = localStorage.getItem('InstrumentType');
        if (
            this.instrumentType === GlobalConstants.instrumentTypeAbs ||
            this.instrumentType === GlobalConstants.instrumentTypeRel ||
            this.instrumentType === GlobalConstants.instrumentTypeAbsdiff
        ) {
            this.utilsService.dataForCalculation.calculationData.staticPressure =
                null;
        } else {
            this.utilsService.dataForCalculation.calculationData.staticPressure =
                Number(staticPressure);
        }
        return this.utilsService.dataForCalculation.calculationData;
    }

    /**
     * Set extension length high side for calculation request
     * @param extensionLengthPlus : extension length
     */
    setExtensionLength(extensionLengthPlus: number) {
        this.utilsService.dataForCalculation.calculationRequest.plusSide.extensionLength =
            extensionLengthPlus;
    }

    /**
     * Set extension length low side for calculation request
     * @param extensionLengthMinus : extension length
     */
    setExtensionLengthMinusSide(extensionLengthMinus?: number) {
        if (
            this.utilsService.dataForCalculation.calculationRequest
                .minusSide !== null
        ) {
            this.utilsService.dataForCalculation.calculationRequest.minusSide.extensionLength =
                Number(extensionLengthMinus);
        }
    }

    /**
     * Set calculation request minus side to null when low side values are not selected
     */
    setMinusSideNull() {
        this.utilsService.dataForCalculation.calculationRequest.minusSide =
            null;
    }

    /**
     * Get the calculaation request object for http call
     * @returns the calculation request object
     */
    setRequestData() {
        return this.utilsService.dataForCalculation.calculationRequest;
    }

    /**
     * Check if the overall system is green, yellow or red
     * @returns text to be displayed based on the overall system calculation
     */
    overallSystemText() {
        if (this.overallSystem===1) {
            this.utilsService.internalConfigurationData.measuringpointObject.calculationStatus =
                'green';
            return 'TID_Ok';
        } else if (this.overallSystem ===3) {
            this.utilsService.internalConfigurationData.measuringpointObject.calculationStatus =
                'yellow';
            return 'TID_OptimizationRecommended';
        } else {
            this.utilsService.internalConfigurationData.measuringpointObject.calculationStatus =
                'red';
            return 'TID_OptimizationNeccessary';
        }
    }

    /**
     * Check if the functionality/calibration/temperature error is green, yellow or red
     * @param value : the overall functionality/calibration/temperature error value
     * @returns text to be displayed
     */
    returnSystemOkOrNotOk(value) {
        if (value ===1) {
            return 'TID_Ok';
        } else if (value ===3) {
            return 'TID_OptimizationRecommended';
        } else {
            return 'TID_OptimizationNeccessary';
        }
    }

    /**
     * check if the overall system is red , green or yellow and set the text accordingly in PDF
     */
    overallPdfText() {
        if (this.overallSystem ===1) {
            this.trafficLightText = this.translate.instant(
                'TID_TheSystemIsDimensionedCorrectly'
            );
        } else if (this.overallSystem ===3) {
            this.trafficLightText = this.translate.instant(
                'TID_SystemDimensionedWithRestrictions'
            );
        } else {
            this.trafficLightText = this.translate.instant(
                'TID_SystemNotCorrectDimensioned'
            );
        }
        this.utilsService.dataForCalculation.calculatedResultData.text =
            this.trafficLightText;
    }

    /**
     * Calculate the overall functionality, calibration and temperature error value
     */
    overallSystemCalc() {
        const hundred = 100;
        const zero = 0;
        const dotNine = 0.9;
        const normalLabolCalculationResult=this.calculationResultFromAPI.find(x=>x.isOptimal===false);
    
        this.calculationResult.responseTime.minimumTemperatureResponseTime =
        normalLabolCalculationResult.minimumTemperatureResponseTime;
        this.calculationResult.responseTime.nominalTemperatureResponseTime =
        normalLabolCalculationResult.nominalTemperatureResponseTime;
        this.calculationResult.responseTime.maximumTemperatureResponseTime =
        normalLabolCalculationResult.maximumTemperatureResponseTime;
        this.calculationResult.calibrationStatusId=normalLabolCalculationResult.calibrationStatusId;
        this.calculationResult.temperatureErrorStatusId=normalLabolCalculationResult.temperatureErrorStatusId;
        this.calculationResult.operationalRangeStatusId=normalLabolCalculationResult.operationalRangeStatusId;

        this.calculateTempratureError(normalLabolCalculationResult,this.calculationResult);

        this.calculationResult.calibration.offsetAdjustment =normalLabolCalculationResult.offsetAdjustment * hundred;
    this.calculationResult.calibration.customerOffsetAdjustment =normalLabolCalculationResult.customerOffsetAdjustment * hundred;
    this.calculationResult.calibration.spanAdjustment =normalLabolCalculationResult.spanAdjustment * hundred;
    this.calculationResult.calibration.gradientAdjustment =normalLabolCalculationResult.gradientAdjustment * hundred;
    this.calculationResult.functionality = normalLabolCalculationResult.operationalRange;
    let calibration = Math.max(
        normalLabolCalculationResult.offsetAdjustment,normalLabolCalculationResult.spanAdjustment,normalLabolCalculationResult.gradientAdjustment
    );
        

        /////////////// Customer offset adjustment only with a green and yellow light /////////////////////
        if (
            calibration > zero &&
            calibration <= dotNine &&
            normalLabolCalculationResult.customerOffsetAdjustment > dotNine
        ) {
            calibration = 0.95;
        }
    
        ///////////////////////////End////////////////////////////////////////

        const temperatureError =
            this.calculationResult.nominalTemperatureError.totalMR / hundred;
            this.overallSystem= this.utilsService.internalConfigurationData.measuringpointObject.calculationStatusId;
       
      
        this.utilsService.dataForCalculation.calculatedResultData.functionality =
            this.calculationResult.functionality;
        this.utilsService.dataForCalculation.calculatedResultData.span =
            this.calculationResult.calibration.spanAdjustment;
        this.utilsService.dataForCalculation.calculatedResultData.offset =
            this.calculationResult.calibration.offsetAdjustment;
        this.utilsService.dataForCalculation.calculatedResultData.customerOffset =
            this.calculationResult.calibration.customerOffsetAdjustment;
        this.utilsService.dataForCalculation.calculatedResultData.gradient =
            this.calculationResult.calibration.gradientAdjustment;
        this.utilsService.dataForCalculation.calculatedResultData.calibration =
            calibration;
        this.utilsService.dataForCalculation.calculatedResultData.temperatureError =
            temperatureError;
        this.utilsService.dataForCalculation.calculatedResultData.overallSystem =
            this.overallSystem;
        this.functionalityLabel = this.returnSystemOkOrNotOk(
            this.calculationResult.operationalRangeStatusId
        );
        this.calibrationLabel = this.returnSystemOkOrNotOk(this.calculationResult.calibrationStatusId);
        this.temperatureErrorLabel =
            this.returnSystemOkOrNotOk(this.calculationResult.temperatureErrorStatusId);
        this.overallPdfText();
    }

    calculateTempratureError(normalLabolCalculationResult,calculationResult:CalculationResult) {
        calculationResult.nominalTemperatureError = normalLabolCalculationResult.nominalTemperatureError;
        calculationResult.nominalTemperatureError.totalRemoteSeal = normalLabolCalculationResult.nominalTemperatureError.remoteSealAbsoluteTotal
        calculationResult.nominalTemperatureError.totalRemoteSealMR = normalLabolCalculationResult.nominalTemperatureError.remoteSealRelativeTotal

        calculationResult.nominalTemperatureError.totalTransmitter = normalLabolCalculationResult.nominalTemperatureError.transmitterAbsoluteTotal;
        calculationResult.nominalTemperatureError.totalTransmitterMR =normalLabolCalculationResult.nominalTemperatureError.transmitterRelativeTotal;
        calculationResult.nominalTemperatureError.totalTempError =normalLabolCalculationResult.nominalTemperatureError.totalAbsoluteTemperatureError
        calculationResult.nominalTemperatureError.totalMR =normalLabolCalculationResult.nominalTemperatureError.totalRelativeTemperatureError;

            ///////////////////max temp error////////////
            
            calculationResult.maximumTemperatureError =
            normalLabolCalculationResult.maximumTemperatureError;
        calculationResult.maximumTemperatureError.totalRemoteSeal = normalLabolCalculationResult.maximumTemperatureError.remoteSealAbsoluteTotal
        calculationResult.maximumTemperatureError.totalRemoteSealMR =normalLabolCalculationResult.maximumTemperatureError.remoteSealRelativeTotal;

        calculationResult.maximumTemperatureError.totalTransmitter =normalLabolCalculationResult.maximumTemperatureError.transmitterAbsoluteTotal;
        calculationResult.maximumTemperatureError.totalTransmitterMR =normalLabolCalculationResult.maximumTemperatureError.transmitterRelativeTotal;
        calculationResult.maximumTemperatureError.totalTempError =normalLabolCalculationResult.maximumTemperatureError.totalAbsoluteTemperatureError;
        calculationResult.maximumTemperatureError.totalMR =normalLabolCalculationResult.maximumTemperatureError.totalRelativeTemperatureError;
    }

  
    /**
     * Get all the calculated results
     */
    calculatedResultData() {
        this.calculationResult = new CalculationResult();
        this.calculationOptimalResult = new CalculationResult();
        this.utilsService.dataForCalculation.calculationData.tReference = 25;
        this.temperatureUnit =this.dataStoreService.getCurrentData(this.dataStoreService.temperatureUnit);
        this.temperatureUnit = this.dataStoreService.getCurrentData(
            this.dataStoreService.temperatureUnit
        );
        this.overallSystemCalc();
    }
    convertTemperatureValueByUnit(unitValue:string)
    {
        const celciousUnitValue='\xB0C';
        if (this.temperatureUnit !== celciousUnitValue) {
            this.utilsService.dataForCalculation.calculationData.tProcessMin = this.unitHandlingService.temperatureConversion(this.utilsService.dataForCalculation.calculationData.tProcessMin,unitValue);
            this.utilsService.dataForCalculation.calculationData.tProcessNormal = this.unitHandlingService.temperatureConversion(this.utilsService.dataForCalculation.calculationData.tProcessNormal,unitValue);
            this.utilsService.dataForCalculation.calculationData.tProcessMax = this.unitHandlingService.temperatureConversion(this.utilsService.dataForCalculation.calculationData.tProcessMax,unitValue);
            this.utilsService.dataForCalculation.calculationData.tAmbientMin = this.unitHandlingService.temperatureConversion(this.utilsService.dataForCalculation.calculationData.tAmbientMin,unitValue);     
            this.utilsService.dataForCalculation.calculationData.tAmbientNormal = this.unitHandlingService.temperatureConversion(this.utilsService.dataForCalculation.calculationData.tAmbientNormal,unitValue);
            this.utilsService.dataForCalculation.calculationData.tAmbientMax = this.unitHandlingService.temperatureConversion(this.utilsService.dataForCalculation.calculationData.tAmbientMax,unitValue);
            }
    }

    convertResultpageTempratureUnitBySelectedUnit()
    {
        const ferniteUnitValue='\xB0F';
            if(this.temperatureUnit===ferniteUnitValue)
            {
                this.utilsService.dataForCalculation.calculationData.tReference=77;
            }
            this.convertTemperatureValueByUnit(ferniteUnitValue);
    }

    getOptimalCalculationResultData()
    {
        const optimalCalculationResult=this.calculationResultFromAPI.find(x=>x.isOptimal===true);
        if(optimalCalculationResult)
        {
        this.calculationOptimalResult.responseTime.minimumTemperatureResponseTime =optimalCalculationResult.minimumTemperatureResponseTime;
        this.calculationOptimalResult.responseTime.nominalTemperatureResponseTime =optimalCalculationResult.nominalTemperatureResponseTime;
        this.calculationOptimalResult.responseTime.maximumTemperatureResponseTime =optimalCalculationResult.maximumTemperatureResponseTime;
        this.calculateTempratureError(optimalCalculationResult,this.calculationOptimalResult);

        this.calculationOptimalResult.calibration.offsetAdjustment =optimalCalculationResult.offsetAdjustment* 100;;
    this.calculationOptimalResult.calibration.customerOffsetAdjustment =optimalCalculationResult.customerOffsetAdjustment* 100;;
    this.calculationOptimalResult.calibration.spanAdjustment =optimalCalculationResult.spanAdjustment * 100;;
    this.calculationOptimalResult.calibration.gradientAdjustment =optimalCalculationResult.gradientAdjustment *100;
    this.calculationOptimalResult.functionality = optimalCalculationResult.operationalRange;
        }
    }

}
