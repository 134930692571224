/**
 * Copyright C) Siemens AG 2022. All Rights Reserved. Confidential

 * About File description :
 *
 * This file use AppRoutingModule and it is used to set the routes for the application.
 *
*/

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

// The various routes used in the appliction
const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    data: { title: 'home' },
  },
  {
    path: 'logout',
 redirectTo:'/',
 pathMatch: 'full'
  },
  {
    path: 'main',
    loadChildren: () => import('./main/main.module').then((m) => m.MainModule),
  },
  {
    path: 'close-modal',
    loadChildren: () =>
      import('./Modals/close-modal/close-modal.module').then(
        (m) => m.CloseModalModule
      ),
  },
  {
    path: 'save-modal',
    loadChildren: () =>
      import('./Modals/save-modal/save-modal.module').then(
        (m) => m.SaveModalModule
      ),
  },
  {
    path: 'delete-user',
    loadChildren: () =>
      import('./Modals/delete-user/delete-user.module').then(
        (m) => m.DeleteUserModule
      ),
  },
  {
    path: 'edit-user',
    loadChildren: () =>
      import('./Modals/edit-user/edit-user.module').then(
        (m) => m.EditUserModule
      ),
  },
  {
    path: 'download-pdf-modal',
    loadChildren: () =>
      import('./Modals/download-pdf/download-pdf.module').then(
        (m) => m.DownloadPdfModule
      ),
  },
  {
    path: 'disclaimer-modal',
    loadChildren: () =>
      import('./Modals/disclaimer-modal/disclaimer-modal.module').then(
        (m) => m.DisclaimerModalModule
      ),
  },
  {
    path: 'height-difference-modal',
    loadChildren: () =>
      import('./Modals/hight-difference/hight-difference.module').then(
        (m) => m.HightDifferenceModule
      ),
  },
  {
    path: 'cancel-modal',
    loadChildren: () =>
      import('./Modals/cancel-modal/cancel-modal.module').then(
        (m) => m.CancelModalModule
      ),
  },
  {
    path: 'user-management',
    loadChildren: () =>
      import('./user-management/user-management/user-management.module').then(
        (m) => m.UserManagementModule
      ),
  },
  {
    path: 'information',
    loadChildren: () =>
      import('./Modals/information-modal/information-modal.module').then(
        (m) => m.InformationModalModule
      ),
  },
  {
    path: 'terms-of-use',
    loadChildren: () =>
      import('./Modals/terms-of-use/terms-of-use.module').then(
        (m) => m.TermsOfUseModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    useHash: true,
    scrollPositionRestoration: 'enabled'
}),
    TranslateModule,
  ],
  exports: [RouterModule, TranslateModule],
})
export class AppRoutingModule {}
