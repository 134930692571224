/**
 * Copyright (C) Siemens AG 2022. All Rights Reserved. Confidential

 * About File description :
 *
 * This file BackendDataService service calls the backend API's.
 *
 *
*/

import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResultCalculationService } from './result-calculation.service';
import { retry } from 'rxjs/operators';
import { CalculationResult, GlobalConstants } from '../common/global-constants';
import { TermsOfUseComponent } from '../Modals/terms-of-use/terms-of-use.component';
import { UtilsService } from './utils.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { LabomResult } from './load-data';
import { Message } from './save-data';
@Injectable({
    providedIn: 'root',
})
export class BackendDataService {
    public userName: string;
    public userRole: string;
    public displayHeaderMenu = false;
    resultData: object;
    displayData: boolean;
    labomApiUrl: string;
    constructor(
        private readonly http: HttpClient,
        private readonly resultCalculation: ResultCalculationService,
        private readonly modalService: BsModalService,
        private readonly utilsService: UtilsService
    ) {
        this.labomApiUrl = environment.labomApi;
    }

    /**
     * Get logged-in user name
     */
    public getUserName() {
        this.http.get('/api/rsct/whoiam').subscribe(
            (result: {
                id: string;
                mail: string;
                role: string;
                country: string;
            }) => {
                this.userName = result.mail;
                this.userRole = result.role;
                sessionStorage.setItem('userRole', result.role);
                this.displayData = true;
                const termsOfUse = JSON.parse(
                    sessionStorage.getItem('terms_of_use')
                );
                const acceptedTermsOfUse = termsOfUse
                    ? termsOfUse
                    : this.utilsService.acceptedTerms;
                if (!acceptedTermsOfUse) {
                    this.modalService
                        .show(TermsOfUseComponent, {
                            backdrop: 'static',
                            keyboard: false,
                            class: 'custom-class',
                        })
                        .setClass('modal-xl');
                }
                if (
                    result.role === GlobalConstants.adminUser ||
                    result.role === GlobalConstants.testAdminUser
                ) {
                    this.displayHeaderMenu = true;
                } else {
                    this.displayHeaderMenu = false;
                }
                this.getTokenForLabom();
            },
            (err) => {
                this.displayData = false;
            }
        );
    }

    /**
     * Get access-token for labom
     */
    public getTokenForLabom() {
        this.http.get('/api/rsct/token').subscribe(
            (result: { access_token: string }) => {
                sessionStorage.setItem('Token', result.access_token);
            },
            (err) => {}
        );
    }

    /**
     * Get data based on the url
     * @param suburl : suburl of the data to be recieved
     * @returns: url data as observable
     */
    public getLaboom(suburl: string): Observable<any> {
        const language = sessionStorage.getItem('user_lang');
        const token = sessionStorage.getItem('Token');
        const header = {
            headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        };
        const url = `${this.labomApiUrl}/v1/${suburl}language=${language}`;
        return this.http.get(url, header);
    }

    /**
     * Get data based on the url for labom
     * @param suburl :  suburl of the data to be recieved
     * @returns url data as a resolved promise on success else error on failure
     */
    public loadAllLabomData(suburl: string) {
        return new Promise((resolveAllData, rejectRequest) => {
            this.getLaboom(suburl)
                .pipe(retry(1))
                .subscribe(
                    (value) => {
                        resolveAllData(value);
                    },
                    (error) => {
                        rejectRequest(error);
                    }
                );
        });
    }

    

    /**
     * Get all the stored configuration/ a single configuration based on suburl
     * @param suburl : suburl of the data to be recieved
     * @returns url data as a resolved promise on success else error on failure
     */
    loadAllConfigurations(suburl: string): Promise<any> {
        return new Promise((resolveLoadAll, reject) => {
            const token = sessionStorage.getItem('Token');
            const header = {
                headers: new HttpHeaders().set(
                    'Authorization',
                    `Bearer ${token}`
                ),
            };
            const url = `/api/rsct/${suburl}`;
            this.http.get(url, header).subscribe(
                (res) => {
                    resolveLoadAll(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    /**
     * Get the data from the backend based on the suburl
     * @param suburl suburl of the data to be recieved
     * @returns url data as a resolved promise on success else error on failure
     */
    public loadAllData(suburl: string): Promise<any> {
        return new Promise((resolveLoadAll, rejectRequest) => {
            const language = sessionStorage.getItem('user_lang');
            const url = `/api/rsct/${suburl}`;
            this.http.get(url).subscribe(
                (res) => {
                    resolveLoadAll(res);
                },
                (err) => {
                    rejectRequest(err);
                }
            );
        });
    }

    /**
     * Get the data from the backend based on the suburl
     * @param suburl suburl of the data to be recieved
     * @param query param
     * @returns url data as a resolved promise on success else error on failure
     */
    public loadAllDataWithQueryParam(
        suburl: string,
        params: HttpParams
    ): Promise<any> {
        return new Promise((resolveLoadAll, rejectRequest) => {
            const url = `/api/rsct/${suburl}`;
            this.http
                .get(url, {
                    params: params,
                })
                .subscribe(
                    (res) => {
                        resolveLoadAll(res);
                    },
                    (err) => {
                        rejectRequest(err);
                    }
                );
        });
    }

    /**
     * Get all the different user roles available in the backend
     * @param suburl :suburl of the data to be recieved
     * @returns url data as a resolved promise on success else error on failure
     */
    getRoles(suburl: string): Promise<any> {
        return new Promise((resolveLoadAllUsers, reject) => {
            const url = `/api/rsct/${suburl}`;
            this.http.get(url).subscribe(
                (res) => {
                    resolveLoadAllUsers(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    /**
     * Delete the user-details from the backend based on the user ID
     * @param suburl suburl of the data to be recieved
     * @returns sucess message as resolved promise or failure as rejected
     */
    deleteUserData(suburl: string): Promise<any> {
        return new Promise((resolveLoadAllUsers, reject) => {
            const url = `/api/rsct/users/${suburl}`;
            this.http.delete(url).subscribe(
                (res) => {
                    resolveLoadAllUsers(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    /**
     * Put request to edit the user data in the backend
     * @param suburl suburl of the data to be changed
     * @param data data to be passed in the request
     * @returns sucess message as resolved promise or failure as rejected
     */
    editUserData(suburl: string, data: any): Promise<any> {
        return new Promise((resolveLoadAllUsers, reject) => {
            const language = sessionStorage.getItem('user_lang');
            const url = `/api/rsct/users/${suburl}?language=${language}`;
            this.http.put(url, data).subscribe(
                (res) => {
                    resolveLoadAllUsers(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    /**
     * Put request to load lazy data in the backend
     * @param suburl suburl of the data to be changed
     * @param params :query param to be passed in the request
     * @returns sucess message as resolved promise or failure as rejected
     */

    loadPaginationData(suburl: string, params: HttpParams): Observable<any> {
        const url = `/api/rsct/${suburl}`;
        return this.http.get(url, {
            params: params,
        });
    }

      /**
     * Post request to get the archive/Restore Data based on the archive/restore flag
     * @param id to be passed in the request
     * @returns url data as a resolved promise on success else error on failure
     */
      archiveORRestoreData(url:string): Promise<any> {
        return new Promise((resolveLoadAllUsers, rejectData) => {
            this.http.patch(url,{}).subscribe(
                (res) => {
                    resolveLoadAllUsers(res);
                },
                (err) => {
                    rejectData(err);
                }
            );
        });
    }


    saveConfigurationForMLFB() {
        this.utilsService.internalConfigurationData.measuringpointObject.uniqueId = null;
        this.utilsService.internalConfigurationData.measuringpointObject.saveDate = null;
        this.utilsService.internalConfigurationData.measuringpointObject.userName = null;
        return new Promise((resolveLoadAllUsers, reject) => {
            const language = sessionStorage.getItem('user_lang');
            const url = `/api/rsct/calculations?language=${language}&withoutSave=true`;
            this.http.post(url, this.utilsService.internalConfigurationData).subscribe(
                (res: {
                    measuringpointObject: any;
                    optionObject: object;
                    messageObject: any;
                    results:any[];
                    configurationObject: {
                        customID: string;
                        plusSide: {
                            mountingElement2: string | number;
                        };
                    };
                }) => {
                    this.utilsService.internalConfigurationData.measuringpointObject =
                        res.measuringpointObject;
                        this.utilsService.internalConfigurationData.messageObject = res.messageObject;
                        this.resultCalculation.calculationResultFromAPI=res.results;
                    resolveLoadAllUsers(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    /**
     * Save the configuration data in the backend
     * @returns MLFB data as a resolved promise or error message on rejection
     */
    saveConfigurationRequestInternal(): Promise<any> {
        this.utilsService.internalConfigurationData.measuringpointObject.uniqueId = null;
        this.utilsService.internalConfigurationData.measuringpointObject.saveDate = null;
        this.utilsService.internalConfigurationData.measuringpointObject.userName = null;
        return new Promise((resolveLoadAllUsers, reject) => {
            const language = sessionStorage.getItem('user_lang');
            const url = `/api/rsct/calculations?language=${language}`;
            this.http.post(url, this.utilsService.internalConfigurationData).subscribe(
                (res: {
                    measuringpointObject: any;
                    optionObject: object;
                    results:any[];
                    configurationObject: {
                        customID: string;
                        plusSide: {
                            mountingElement2: string | number;
                        };
                    };
                    messageObject:Message[];
                }) => {
                    this.utilsService.internalConfigurationData.measuringpointObject =
                        res.measuringpointObject;
                        this.utilsService.internalConfigurationData.messageObject=res.messageObject;
                        this.resultCalculation.calculationResultFromAPI=res.results;
                    resolveLoadAllUsers(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    downloadPdf(url:string)
    {
           return this.http.get(url, { responseType: 'blob' as 'json'});
    }
}
