/**
 * Copyright (C) Siemens AG 2022. All Rights Reserved. Confidential

 * About File description :
 *
 * This file is used to display terms of use for the application.
 *
 *
*/
import { AfterViewInit, Component, OnInit,Inject } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UtilsService } from '../../services/utils.service';
import { NgxLoaderService } from 'src/app/services/ngx-loader.service';
import {cityAndcountryMappingObject} from 'src/app/common/city-country'
import { UntypedFormControl } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { CustomeFormValidationService } from 'src/app/services/custome-form-validation.service';
@Component({
    selector: 'app-terms-of-use',
    templateUrl: './terms-of-use.component.html',
    styleUrls: ['./terms-of-use.component.scss'],
})
export class TermsOfUseComponent implements OnInit, AfterViewInit {
    germanLang='Deutsch';
    englishLang = 'English';
    SpanishLang = 'Español';
    indonesianLang = 'Indonesia';
    frenchLang= 'Français';
    languageFormControl!: UntypedFormControl;
    languages=[this.englishLang,this.germanLang,this.frenchLang,this.SpanishLang,this.indonesianLang];
    title='Remote Seal Calculation Tool Supplemental Terms';
    selectedLanguage=this.englishLang;
    constructor(
        private readonly modalService: BsModalService,
        private readonly utilsService: UtilsService,
        private loader: NgxLoaderService,
        @Inject(DOCUMENT) private readonly document: Document,
        public customeFormValidationService:CustomeFormValidationService
    ) {
        this.languageFormControl = new UntypedFormControl(null);
    }

    ngOnInit(): void {
          this.languageFormControl.valueChanges.subscribe(selectedlang=>
            {
                this.updateLanguageData(selectedlang);
            });
    }

    updateLanguageData(selectedlang:string)
    {
        this.selectedLanguage=selectedlang;
        switch (selectedlang) {
            case this.englishLang:
                this.document.documentElement.lang ='en';
             this.title='Remote Seal Calculation Tool Supplemental Terms';
              break;
            case this.germanLang:
                this.document.documentElement.lang ='de';
              this.title="Berechnungs-Tool für Druckmittler – Ergänzende Bedingungen";
              break;
            case this.frenchLang:
                this.document.documentElement.lang ='fr';
                this.title="Conditions supplémentaires pour l'outil RSCT de calcul du séparateur";
              break;
            case this.SpanishLang:
                this.document.documentElement.lang ='es';
                this.title="Términos complementarios de la herramienta de cálculo del sello separador";
              break;
              case this.indonesianLang:
                this.document.documentElement.lang ='id';
                this.title=" Ketentuan Tambahan Remote Seal Calculation Tool (Alat Penghitungan Segel Jarak Jauh)";
                break;
          }
    }

    ngAfterViewInit() {
        this.displayTermsOfUse();
       
    }

    close() {
        this.loader.presentLoading();
        this.modalService.hide();
        this.customeFormValidationService.logout();
        this.loader.dismissLoading();
    }

    accept() {
        this.utilsService.acceptedTerms = true;
        this.document.documentElement.lang = sessionStorage.getItem('user_lang');
        sessionStorage.setItem(
            'terms_of_use',
            JSON.stringify(this.utilsService.acceptedTerms)
        );
        this.modalService.hide();
    }

    openHelp(pdfLocation: string) {
        window.open(pdfLocation, '_blank').focus();
    }


    displayTermsOfUse()
    {
      let country:string | null=null;
      if (Intl) {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const timeZoneList = timeZone.split("/");
        const city = timeZoneList[timeZoneList.length - 1];
        country = cityAndcountryMappingObject[city];
        this.updateSystemlanguageToShowCountryCode(country);
      }
      if(country==null)
      {
        const language = sessionStorage.getItem('user_lang');
        if(language==='de')
        {
        this.selectedLanguage=this.germanLang;
        }
        else
        {
            this.selectedLanguage=this.englishLang;
        }

      }
      this.updateLanguageData(this.selectedLanguage);
      this.languageFormControl.setValue(this.selectedLanguage, {
        emitEvent: false,
      });
    }

     updateSystemlanguageToShowCountryCode(country: string| null) {
        if (country === 'France') {
            this.selectedLanguage = this.frenchLang;
            this.updateLanguageData(this.frenchLang);
        }
        else if (country === 'Colombia') {
            this.selectedLanguage = this.SpanishLang;
            this.updateLanguageData(this.SpanishLang);
        }
        else if (country === 'Mexico') {
            this.selectedLanguage = this.SpanishLang;
            this.updateLanguageData(this.SpanishLang);
        }
        else if (country === 'Bangkok') {
            this.selectedLanguage = this.indonesianLang;
            this.updateLanguageData(this.indonesianLang);
        }
        else if (country === 'Morocco') {
            this.selectedLanguage = this.frenchLang;
            this.updateLanguageData(this.frenchLang);
        }
        else if (country === 'Germany') {
            this.selectedLanguage = this.germanLang;
            this.updateLanguageData(this.germanLang);
        }
        else if (country === 'Spain') {
            this.selectedLanguage = this.SpanishLang;
            this.updateLanguageData(this.SpanishLang);
        }
        else if (country === 'Indonesia') {
            this.selectedLanguage = this.indonesianLang;
            this.updateLanguageData(this.indonesianLang);
        }
    }
}
