/**
 * Copyright C) Siemens AG 2022. All Rights Reserved. Confidential

 * About File description :
 *
 * This file use AppModule and it is used to intamate/initate the
 * module, plugin service used in the entire application.
 *
*/

import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  APP_INITIALIZER,
  LOCALE_ID,
  ErrorHandler,
} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { SiBreadcrumbModule } from '@simpl/siemens-brand-ng/breadcrumb';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SiDropdownModule } from '@simpl/siemens-brand-ng/dropdown';
import { SiFormGroupModule } from '@simpl/siemens-brand-ng/form-group';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  NgxUiLoaderRouterModule,
} from 'ngx-ui-loader';
import { ErrorModalComponent } from './Modals/error-modal/error-modal.component';
import { HttpInterceptorErrors } from './services/http-interceptor';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { TooltipConfig, TooltipModule } from 'ngx-bootstrap/tooltip';
import { filter } from 'rxjs/operators';
import { SiHeaderModule } from '@simpl/siemens-brand-ng/header';
import { SiFooterModule } from '@simpl/siemens-brand-ng/footer';
import { A11yModule } from '@angular/cdk/a11y';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
import { firstValueFrom } from 'rxjs';
import { SiLocalizationNgxTModule } from '@simpl/siemens-brand-localize-with-ngx-translate';
import { AuthService } from '@auth0/auth0-angular';
registerLocaleData(localeDe, 'de');


export  function loginCheck(auth: AuthService) {
  
  return (): Promise<void> => {
    
    return new Promise<void>(async (resolve) => {
      const isAuthenticated = await firstValueFrom(auth.isAuthenticated$);
  
        if(!isAuthenticated)
        {
          auth.loginWithRedirect({appState: {
            target: '/',
          }}).subscribe(result=>{
            resolve();
          });
        }
        else{
          resolve();
        }
      
    });
  };
}

/**
 * Configuring the loader/spinner used in the
 * application
 */
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: 'red',
  bgsOpacity: 0.5,
  bgsPosition: 'bottom-right',
  bgsSize: 60,
  bgsType: 'ball-spin-clockwise',
  blur: 5,
  delay: 0,
  fastFadeOut: true,
  fgsColor: 'white',
  fgsPosition: 'center-center',
  fgsSize: 60,
  fgsType: 'ball-spin-clockwise',
  gap: 24,
  logoPosition: 'center-center',
  logoSize: 120,
  logoUrl: '',
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(40, 40, 40, 0.8)',
  pbColor: 'red',
  pbDirection: 'ltr',
  pbThickness: 3,
  hasProgressBar: true,
  text: '',
  textColor: '#FFFFFF',
  textPosition: 'center-center',
  maxTime: -1,
  minTime: 3000,
};

@NgModule({
  declarations: [
    AppComponent,
    //  ErrorDialogComponent,
    ErrorModalComponent,
    // TermsOfUseComponent,
    // SingleSidePdfComponent
  ],
  imports: [
    // Angular
    TooltipModule,
    BrowserAnimationsModule,
    // SiMPL Peers
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    PaginationModule.forRoot(),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    // NgxUiLoaderHttpModule.forRoot({ showForeground: true }),
    // Loader/Spinner which is displayed when navigating from one page to another
    NgxUiLoaderRouterModule.forRoot({ showForeground: true }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    // SiMPL
    SiBreadcrumbModule,
    SiFooterModule,
    // SiNewtonHeaderModule,
    SiDropdownModule,
    SiFormGroupModule,
    A11yModule,
    SiHeaderModule,
    // TooltipModule,
    // SiNewtonHeaderModule,
    // Application local
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SiLocalizationNgxTModule,
    AuthModule.forRoot({
      ...environment.auth,
      httpInterceptor: {
        ...environment.httpInterceptor,
      },
    }),
  ],
  // Gloabl error handler
  providers: [
    TooltipConfig,
   // { provide: LOCALE_ID, useValue: 'en' },
   {
    provide: APP_INITIALIZER,
    useFactory: loginCheck,
    multi: true,
    deps: [AuthService],
  },
   {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthHttpInterceptor,
    multi: true,
  },
  {
    provide: Window,
    useValue: window,
  },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initTranslation,
    //   deps: [TranslateService],
    //   multi: true,
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorErrors,
      multi: true,
    },
    { provide: APP_INITIALIZER, useFactory: initSentry, multi: true },
    { provide: ErrorHandler, useFactory: provideErrorHandler },
  ],
  // entryComponents: [ErrorDialogComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

export function initTranslation(translate: TranslateService) {
  return () => {
    const bowswerLang = translate.getBrowserLang();
    if (bowswerLang === 'en' || bowswerLang === 'de') {
      sessionStorage.setItem('user_lang', bowswerLang);
    } else {
      sessionStorage.setItem('user_lang', 'de');
    }
    translate.setDefaultLang(bowswerLang ? bowswerLang: 'de');
    return Promise.resolve(translate.use(bowswerLang));
  };
}

/**
 * To load the JSON to be used by the application for translation
 */
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function initSentry() {
  return (): Promise<void> => {
    return new Promise<void>((resolve) => {
      if (environment.production) {
        Sentry.init({
          dsn: environment.sentryDNS,
          environment: environment.name,
          release: environment.version,
          integrations: [
            new Integrations.BrowserTracing()
          ],
          tracesSampler: (samplingContext) => {
            return 0.1;
          }
        });
      }
      resolve();
    });
  };
}

export function provideErrorHandler() {
  if (environment.name !== 'Local') {
    return Sentry.createErrorHandler();
  } else {
    return new ErrorHandler();
  }
}
