/**
 * Copyright (C) Siemens AG 2022. All Rights Reserved. Confidential

 * About File description :
 *
 * This file UnitHandlingService service provides values for unit handling.
 *
 *
*/

import { Injectable } from '@angular/core';
import { RuntimeParserService } from './runtime-parser.service';

@Injectable({
    providedIn: 'root',
})
export class UnitHandlingService {
    public typeOfSystem: string;
    public counter = 0;
    public ambientTempCounter = 0;
    public processTempCounter = 0;
    public nextTemperatureUnit: string;
    public pressureCounter = 0;
    loadSetupTabUnits: boolean;
    diaplayMediumTemperatureText = false;
    constructor(public parserService: RuntimeParserService) {}

    /**
     * Distance conversion
     * @param unit the unit selcted by the user
     * @returns the value which needs to be multiplied or divided with the set the backend values
     */
    public distanceConversion(unit: string) {
        let value: number;
        const distance = [
            { m: 1 },
            { mm: 1000 },
            { inch: 39.37 },
            { ft: 3.28 },
        ];
        distance.forEach((element) => {
            if (unit in element) {
                value = element[unit];
            }
        });
        return value;
    }

    /**
     * pressure conversion
     * @param unit the unit selcted by the user
     * @returns the value which needs to be multiplied or divided with the set the backend values
     */
    public pressureConversion(unit: string) {
        let value: number;
        const pressure = [
            { bar: 1 },
            { mbar: 1000 },
            { kPa: 100 },
            { MPa: 0.1 },
            { 'inch H20': 401.4630759756 },
            { ftH20: 33.4552563313 },
            { psi: 14.50377377302 },
        ];
        pressure.forEach((element) => {
            if (unit in element) {
                value = element[unit];
            }
        });
        return value;
    }

   /**
    * temperature conversion
    * @param value  : the value to be converted
    * @param unit: the unit selected by user
    * @returns the converted value
    */
    public temperatureConversion(value: number, unit: string) {
        if (unit === '\xB0C') {
            return ((value - 32) * 5) / 9;
        } else {
            return (value * 9) / 5 + 32;
        }
    }
}
