<a
    *ngIf="this.service.displayData"
    routerLink="/"
    (click)="skipToMain('main-content')"
    style="
      position: absolute;
      height: 30px;
      padding: 8px;
      transform: translateY(-160%);
      transition: transform 0.3s;
      left:5%;
    "
    tooltip="skip to main"
    >Skip to main
  </a>
  <a
    *ngIf="this.service.displayData"
    routerLink="/"
    (click)="skipToMain('footer-content')"
    style="
      position: absolute;
      height: 30px;
      padding: 8px;
      transform: translateY(-160%);
      transition: transform 0.3s;
      left: 10%;
    "
    tooltip="skip to footer"
    >Skip to footer
  </a>
<header>
  <nav class="{{this.headerClass}}"  siHeader>
    <div
      class="container-fluid"
      style="padding-left: 100px; padding-right: 100px;"
      siHeaderContainer
    >
      <a href="#" aria-label="Siemens logo" class="si-header-brand" title='Siemens logo' style="margin-top: 10px;">
        <si-logo ></si-logo>
      </a>
      <button class="si-header-toggler" type="button" siHeaderToggler aria-label="Toggle navigation">
        <span class="siemens-menu"></span>
    </button>
      <div class="si-header-nav-icons" style="padding-top: 20px;">
        <div
          class="si-header-item"
          style="padding-top: 10px;"
          *ngIf="this.service.displayData" [attr.aria-label]="this.translate.instant('TID_Catalog')"
        >
          <a id="catalog-link"  tabindex="0" [attr.aria-label]="this.translate.instant('TID_Catalog')"
            class="si-header-link"
            style="display: flex;cursor: pointer;"
            (click)="this.openCatalogLink()"
          >
            <span class="siemens-link" style="padding-top: 5px;"></span>
            <p style="font-size: 16px;padding-left: 3px;">
              {{"TID_Catalog" | translate}}
            </p>
          </a>
        </div>

         <div
          class="si-header-item"
          style="padding-top: 10px;"
          *ngIf="this.service.displayData" attr.aria-label="PIA"
        >
          <a  tabindex="0" id="PIA-link" attr.aria-label="PIA"
            class="si-header-link"
            style="display: flex;cursor: pointer;"
            (click)="this.openPIALink()"
          >
            <span class="siemens-link" style="padding-top: 5px;"></span>
            <p style="font-size: 16px;padding-left: 3px;">
              PIA
            </p>
          </a>
        </div>
        
        <div class="si-header-item" style="padding-top: 10px;" [attr.aria-label]="this.translate.instant('TID_Contact')" >
          <button id="contact-link" [attr.aria-label]="this.translate.instant('TID_Contact')"
            class="si-header-link"
            style="display: flex;"
            (click)="this.showEmailData(true)"
          >
            <span class="siemens-email" style="padding-top: 5px;"></span>
            <p style="font-size: 16px; padding-left: 2px;">
              {{ "TID_Contact" | translate }}
            </p>
          </button>
          <div [attr.aria-label]="this.translate.instant('TID_Contact')"
            class="si-header-dropdown-menu"
            *ngIf="this.displayEmail"
            tabindex="-1"
            cdkTrapFocus
            [cdkTrapFocusAutoCapture]="true"
            aria-modal="true"
          >
            <div class="si-header-dropdown-menu-wrapper">
              <div class="container">
                <div class="si-header-dropdown-close">
                  <div class="si-header-item">
                    <button id="show-email-link"
                      class="si-header-link"
                      attr.aria-label="{{ 'TID_Close' | translate }}"
                      (click)="this.showEmailData(false)"
                    >
                      <span class="siemens-close"></span>
                    </button>
                  </div>
                </div>
                <div>
                  <div class="si-header-dropdown-head">
                    <label style="padding-bottom: 10px; width: max-content;">
                      {{ "TID_ForQuestionsRegardingTheSoftware" | translate }}
                     
                    </label>
            <p style="width: max-content;text-decoration: underline;"><a class="link-primary" href="mailto:support.rsct.industry@siemens.com">support.rsct.industry@siemens.com</a></p>
                  </div>
                  <div
                    class="si-header-dropdown-head"
                    style="padding-top: 10px;"
                  >
                    <label style="padding-bottom: 10px; width: max-content;">
                      {{
                      "TID_ForQuestionsRegardingYourCalculationContactTechnicalSupport"
                      | translate }}
                    </label>
                    <div class="si-header-item" siHeaderItem>
                      <label style="width: max-content;text-decoration: underline;">
                        <a class="link-primary" href="{{this.contactSupportUrl}}" target="_blank"
                          >{{this.contactSupportUrl}}
                        </a>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="si-header-item" style="padding-top: 10px;" *ngIf="this.service.displayData">
          <span class="siemens-user"></span>
          <label style="font-size: 16px;">
            {{this.service.userName}}
          </label>
        </div> -->
        <div 
          class="si-header-item"
          style="padding-top: 10px;"
          *ngIf="this.service.displayData"
        >
          <button id="show-theme" attr.aria-label="{{this.service.userName}}"
            class="si-header-link"
            (click)="this.showThemeSettings(true)"
            style="display: flex;"
          >
            <span class="siemens-user" style="padding-top: 5px;"></span>
            <p style="font-size: 16px; padding-left: 2px;">
              {{this.service.userName}}
            </p>
          </button>
          <div attr.aria-label="{{this.service.userName}}"
            class="si-header-dropdown-menu"
            *ngIf="this.displayTheme"
            tabindex="-1"
            cdkTrapFocus
            [cdkTrapFocusAutoCapture]="true"
            aria-modal="true"
            style="padding-top: 10px; padding-left: 160px;"
          >
            <div
              class="si-header-item"
              style="float: right; padding-right: 160px;"
            >
              <button id="theme-close-document"
                class="si-header-link"
                attr.aria-label="{{ 'TID_Close' | translate }}"
                (click)="this.showThemeSettings(false)"
              >
                <span class="siemens-close"></span>
              </button>
            </div>
            <div
              class="si-header-dropdown-menu-wrapper"
              style="padding-top: 50px; padding-left: 20px;"
            >
              <div class="si-header-dropdown-head">
                <label>
                  {{ 'TID_UserRole' | translate }}:
                  <label style="padding-left: 10px;">
                    {{this.service.userRole}}
                  </label>
                </label>
              </div>
              <div style="padding-top: 20px; display: flex;">
                <span>
                  <label style="padding-top: 15px;">
                    {{"TID_SelectDisplayBrightness" | translate}}:
                  </label>
                </span>
                <br />
                <span style="width: 300px;">
                  <si-form-group
                    style="padding-left: 10px;"
                    label="{{ 'TID_Brightness' | translate }}" attr.aria-label="{{ 'TID_Brightness' | translate }}"
                  >
                    <si-dropdown [attr.aria-label]="'TID_SelectDisplayBrightness' | translate" [ariaLabel]="'TID_SelectDisplayBrightness' | translate"
                      inputId="theme-selection"
                      [formControl]="formControl"
                      [dropdownOptions]="[this.translate.instant('TID_Light'), this.translate.instant('TID_Dark')]"
                    >
                    </si-dropdown>
                  </si-form-group>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="si-header-item"
          style="padding-top: 10px;"
          *ngIf="this.service.displayData"
        >
          <button id="lang-link" [attr.aria-label]="this.translate.instant('TID_SelectLanguage')"
            class="si-header-link"
            (click)="this.showData(true)"
            style="display: flex;"
          >
            <span class="siemens-language" style="padding-top: 5px;"></span>
            <p style="font-size: 16px; padding-left: 2px;">
              {{ userLanguageList[this.i].name | translate }}
            </p>
          </button>
          <div
            class="si-header-dropdown-menu"
            *ngIf="this.display"
            tabindex="-1"
            cdkTrapFocus
            [cdkTrapFocusAutoCapture]="true"
            aria-modal="true" [attr.aria-label]="this.translate.instant('TID_SelectLanguage')"
          >
            <div class="si-header-dropdown-menu-wrapper">
              <div class="container">
                <div class="si-header-dropdown-close">
                  <div class="si-header-item">
                    <button id="lang-dropdown"
                      attr.aria-label="{{ 'TID_Close' | translate }}"
                      class="si-header-link"
                      (click)="this.showData(false)"
                    >
                      <span class="siemens-close"></span>
                    </button>
                  </div>
                </div>
                <div class="si-header-dropdown-head">
                  <p
                    [attr.aria-label]="this.translate.instant('TID_SelectLanguage')+ ' '+this.translate.instant(userLanguageList[this.j].name)"
                  >
                    {{ "TID_SelectLanguage" | translate }}
                  </p>
                  <div class="si-header-item" siHeaderItem>
                    <button id="select-language"
                      [attr.aria-label]="this.translate.instant('TID_SelectLanguage') + ' '+this.translate.instant(userLanguageList[this.j].name)"
                      class="btn btn-primary"
                      (click)="selectLanguage(this.userLanguageList[this.j])"
                    >
                      {{ userLanguageList[this.j].name | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
        class="si-header-item"
        style="padding-top: 10px;"
      >
        <button id="logout-link" [attr.aria-label]="this.translate.instant('TID_Logout')"
          class="si-header-link"
          (click)="logout()"
          style="display: flex;"
        >
          <span class="siemens-logout" style="padding-top: 5px;"></span>
          <p style="font-size: 16px; padding-left: 2px;">
           {{ 'TID_Logout' | translate }}
          </p>
        </button>
      </div>
      </div>
      <div
      class="si-header-collapse"
      siHeaderCollapse
      *ngIf="this.service.displayData"
    >
      <div class="si-header-collapse-wrapper">
        <div class="container-fluid">
          <ul
            siHeaderMenu
            class="si-header-nav-main"
            *ngIf="this.service.displayHeaderMenu"
          >
            <li class="si-header-item"  siHeaderItem>
              <button
                siHeaderLink
                style="cursor: pointer;"
                class="si-header-link"
                role="menuitem"
                (click)="closePopUp(true, '/')"
              >
                {{"TID_Home" | translate}}
              </button>
            </li>
            <li class="si-header-item"  siHeaderItem>
              <button 
                siHeaderLink
                style="cursor: pointer;"
                class="si-header-link"
                role="menuitem"
                (click)="closePopUp(true, '/user-management')"
              >
                {{"TID_UserManagement" | translate}}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    </div>
  </nav>
</header>

<div class="container-fluid" style="padding-left: 100px; padding-right: 100px;">
  <main id="main-content" [@siRouterFadeAnimation]="getRouteAnimation(route)">
    <ngx-ui-loader></ngx-ui-loader>
    <router-outlet
      *ngIf="this.service.displayData"
      #route="outlet"
    ></router-outlet>
  </main>
</div>

<section role="contentinfo" *ngIf="this.service.displayData">
  <footer
    role="contentinfo"
    id="footer-content"
    class="si-footer si-footer-legal-only"
  >
    <div
      class="container-fluid"
      style="padding-left: 100px;"
    >
      <span class="si-application-name">
        <label>{{ "TID_RemoteSealCalculationTool" | translate }}</label>
        <!-- <si-copyright style="padding-left: 8px;"></si-copyright> -->
        <label style="padding: 8px;">
          {{'TID_Version' | translate}} {{version}}</label
        >
        <span *ngFor="let link of footerLinks" style="padding: 8px;">
          <a
            tabindex="0"
            [href]="link.url"
            class="a3"
            target="_blank"
            rel="noopener"
            >{{link.name | translate}}</a
          >
        </span>
        <a
          tabindex="0"
          class="a3"
          href="#"
          style="cursor: pointer;"
          (click)="this.showDiscalaimer($event)"
        >
          {{'TID_Disclaimer' | translate}}
        </a>
        <a
        tabindex="0"
        class="margin-left-6 a3"
        href="#"
        style="cursor: pointer;"
        (click)="this.showOpenSourceLicenses($event)"
      >
      {{'TID_OpenSourceLicenses' | translate}}
      </a>
      </span>
    </div>
    <!-- <div>
        <button (click)="loadStyle('dark.css')">dark theme</button>
        <button (click)="loadStyle('siemens.css')">light theme</button>
      </div> -->
  </footer>
</section>

<!--use container-fluid instead of container for whole screen -->
