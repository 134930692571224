export const environment = {
    production: true,
    showDebugInformation: false,
    sentryDNS: 'https://8f3b222b3c9d486ba804e51139f5f758@crash.siemens.com/211',
    name: "Production",
    version: '7bcf712a23b95740bf16b9cb50a051692dfcb29b',
    labomApi:'https://api.labom.com/dmprog',
    auth: {
        domain:"siemens-00130.eu.auth0.com",
        clientId:"lDwKQM6CQMXZKGXjKjcFtxEUprlteiTR",
        authorizationParams: {
          audience:"https://siemens-00130.eu.auth0.com/api/v2/",
          redirect_uri: window.location.origin + '/',
          connection: 'main-tenant-oidc',
          scope: 'openid profile email https://rsct.siemens.com/backend'
        },
        error:'/error',
      },
      httpInterceptor: {
        allowedList: ['/*'],
      },
};
