import { CalculationResult } from "../common/global-constants";
import { LoadSavedData, MeasuringpointObject, OptionType } from "./load-data";


export class Message {
     requestMessagesId: number;
     messageType: string;
     message: string;
     messageSource: string;
    constructor() {}
  }

export class InternalConfigurationData {
    measuringpointObject: MeasuringpointObject;
    optionObject:OptionType[] = [];
    messageObject :Message[];
    configurationObject :LoadSavedData;
    constructor() {
        this.measuringpointObject = new MeasuringpointObject();
        this.configurationObject = new LoadSavedData();
    }
}

export class FormDataForUI {
    configuration: Configuration;
    processParameters: ProcessParameters;
    constructor() {
        this.configuration = new Configuration();
        this.processParameters = new ProcessParameters();
    }
}

class SelectUnits {
    distance: string;
    pressure: string;
    temperature: string;
  }
  
  class TransmitterInformation {
    maximum_measuring_span: string;
    sil_device: boolean;
    transmitter_type: string;
  }

  class RemoteSealHighSide
  {
    diaphragm_material:string;
    doubleSide:string;
    extension_length:string;
    nominal_pressure_selection:string;
    nominal_size:string;
    remote_seal_standard:string;
    remote_seal_type:string;
  }

  class RemoteSealLowSide
  {
    diaphragm_material:string;
    extension_length:string;
    nominal_pressure_selection:string;
    nominal_size:string;
    remote_seal_standard:string;
    remote_seal_type:string;
  }

  class MountingHighSide
  {
    capillary_length:string;
    filling_fluid:string;
    height_difference:string;
    inner_diameter_capillary:string;
    mounting_position_of_transmitter:string;
    show_all_possible_mounting_elements:string;
    type_of_mounting:string;
  }

  class MountingLowSide
  {
    capillary_length:string;
    filling_fluid:string;
    height_difference:string;
    inner_diameter_capillary:string;
    mounting_position_of_transmitter:string;
    show_all_possible_mounting_elements:string;
    type_of_mounting:string;
  }
class Configuration {
    SelectUnits :SelectUnits ;
    transmitterInformation :TransmitterInformation;
    remoteSealHighSide :RemoteSealHighSide ;
    remoteSealLowSide :RemoteSealLowSide ;
    mountingHighSide :MountingHighSide;
    mountingLowSide :MountingLowSide ;
    constructor()
    {
        this.SelectUnits=new SelectUnits();
        this.transmitterInformation=new TransmitterInformation();
        this.remoteSealHighSide = new RemoteSealHighSide();
        this.remoteSealLowSide= new RemoteSealLowSide();
        this.mountingHighSide=new MountingHighSide();
        this.mountingLowSide=new MountingLowSide();

    }
}

class AmbientTemperature
{
    maximum_temperature:string;
    minimum_temperature:string;
    nominal_temperature:string;
}

class ProcessTemperature
{
    maximum_temperature:string;
    minimum_temperature:string;
    nominal_temperature:string;
}
class MeasuringRange
{
    from:string;
    to:string;
}
class StaticPressure
{ 
    static_pressure:string;
}

class VaccumService
{
    vaccum_service:string;
}
class ProcessParameters {
    ambientTemp :AmbientTemperature;
    processTemp :ProcessTemperature ;
    measuringRange :MeasuringRange ;
    staticPressure :StaticPressure ;
    vaccumService :VaccumService;

    constructor()
    {
        this.ambientTemp = new AmbientTemperature();
        this.processTemp = new ProcessTemperature();
        this.measuringRange = new MeasuringRange();
        this.staticPressure = new StaticPressure();
        this.vaccumService= new VaccumService();
    }
}
